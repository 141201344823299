import React, { ReactNode, useCallback, useMemo, useState, useEffect } from "react";
import Form, { FormInstance } from "antd/lib/form";
import Input from "antd/lib/input";
import { Select, Option } from "components/Select";
import moment from "moment";
import { isMobile } from "react-device-detect";

interface IProps {
  form: FormInstance;
  errorMessage: (val: string) => ReactNode;
  isError: boolean;
  onValidate: (status: boolean) => void;
  onChangeError: (err: boolean) => void;
  label?: string;
  disabled?: boolean;
  checkAge?: number;
}

const DatePicker: React.FC<IProps> = ({
  form,
  errorMessage,
  onValidate,
  isError,
  onChangeError,
  label,
  disabled,
  checkAge,
}) => {
  const [isChanged, setIsChanged] = useState(false);
  const validateDate = () => {
    !isChanged && setIsChanged(true);
    const birthday = form.getFieldValue("birthday");

    if (!birthday?.day || !(birthday?.month + 1) || !birthday?.year) {
      // return Promise.reject(errorMessage("Birthday is required"));
      onValidate(false);
      return;
    } else if (
      !moment(
        `${birthday.year}-${birthday.month + 1 <= 9 ? "0" : ""}${birthday.month + 1}-${birthday.day <= 9 ? "0" : ""}${
          birthday.day
        }`,
        "YYYY-MM-DD",
        true,
      ).isValid()
    ) {
      return Promise.reject(errorMessage("Invalid date"));
    }
    // return Promise.resolve();
    onValidate(true);
    onChangeError(false);
    return;
  };

  useEffect(() => {
    if (isError) {
      form.setFields([
        {
          name: "birthday",
          errors: [errorMessage("Birthday is required") as string],
          validating: true,
        },
      ]);
    }
  }, [isError, form, errorMessage]);

  const handleValidate = () => {
    if (isError) {
      return Promise.reject(errorMessage("Birthday is required"));
    }
    const birthday = form.getFieldValue("birthday");
    if ((!birthday?.day || !(birthday?.month + 1) || !birthday?.year) && !isChanged) {
      onChangeError(true);
      return Promise.reject(errorMessage("Birthday is required"));
    }

    if (checkAge) {
      const currentDate = new Date();
      let age: number = currentDate.getFullYear() - birthday?.year;
      const monthsDiff = currentDate.getMonth() - birthday?.month;

      if (monthsDiff < 0) {
        age--;
      }

      if (age < checkAge) {
        return Promise.reject(errorMessage(`You must be over ${checkAge} years old to be able to use Komi`));
      }
    }

    return Promise.resolve();
  };

  return (
    <Form.Item
      name="birthday"
      label={label || "Birthday"}
      rules={[{ validator: handleValidate }]}
      validateTrigger={["onChange", "onBlur"]}
      validateStatus={isError ? "error" : "success"}
    >
      <DatePickerForm onChange={validateDate} disabled={disabled} />
    </Form.Item>
  );
};

export default DatePicker;

interface DatePickerFormInterface {
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

const DatePickerForm: React.FC<DatePickerFormInterface> = ({ value = {}, onChange, disabled }) => {
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const dayList = useMemo(() => {
    if (!selectedMonth) return Array.from(Array(31).keys()).map((day) => day + 1);
    const date = moment().month(selectedMonth);
    if (selectedYear) date.year(selectedYear);
    return Array.from(Array(date.daysInMonth()).keys()).map((day) => day + 1);
  }, [selectedMonth, selectedYear]);

  const monthList = useMemo(() => Array.from(Array(12).keys()), []);

  const yearList = useMemo(() => {
    const list = [];
    const yearNow = moment().year();
    for (let i = yearNow; i >= yearNow - 100; i--) {
      list.push(i);
    }
    return list;
  }, []);

  const formatDayAndMonth = useCallback((val: number) => {
    return val < 10 ? `0${val}` : val;
  }, []);

  return (
    <Input.Group compact>
      <Select
        placeholder={isMobile ? "MM" : "Month"}
        onSelect={(val: any) => {
          onChange?.({ ...value, month: val });
          setSelectedMonth(val);
        }}
        disabled={disabled}
      >
        {monthList?.map((month) => (
          <Option value={month} key={month}>
            {moment().month(month).format("MMM")}
          </Option>
        ))}
      </Select>
      <Select
        placeholder={isMobile ? "DD" : "Day"}
        onSelect={(val) => {
          onChange?.({ ...value, day: val });
        }}
        disabled={disabled}
      >
        {dayList?.map((day) => (
          <Option value={day} key={day}>
            {formatDayAndMonth(day)}
          </Option>
        ))}
      </Select>

      <Select
        placeholder={isMobile ? "YYYY" : "Year"}
        onSelect={(val: any) => {
          onChange?.({ ...value, year: val });
          setSelectedYear(val);
        }}
        disabled={disabled}
      >
        {yearList?.map((year) => (
          <Option value={year} key={year}>
            {year}
          </Option>
        ))}
      </Select>
    </Input.Group>
  );
};
