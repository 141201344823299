import { FormInstance } from "antd";
import { isValidPhoneNumber } from "libphonenumber-js";

import { hasNumber, hasSpecialCharacter, hasUppercase } from "utils/string";

const generateFieldError = (
  defaultMessage: string,
  errorComponent?: (message: string) => JSX.Element,
  errorMessage?: string,
) => {
  if (errorComponent) {
    return errorComponent(errorMessage || defaultMessage);
  } else {
    return errorMessage || defaultMessage;
  }
};

const formatFieldName = (fieldName: string): string =>
  fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

export const onlyAlphanumeric =
  (
    form: FormInstance,
    fieldName: string,
    errorMessage?: string,
    errorComponent?: (message: string) => JSX.Element,
  ) =>
  (): Promise<void> => {
    const defaultMessage = `${formatFieldName(
      fieldName,
    )} can only contain alphanumeric characters (letters and numbers)`;
    try {
      const fieldValue = form.getFieldValue(fieldName)?.trim();
      if (!/^[a-zA-Z0-9]*$/.test(fieldValue)) {
        return Promise.reject(generateFieldError(defaultMessage, errorComponent, errorMessage));
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(generateFieldError(defaultMessage, errorComponent, errorMessage));
    }
  };

export const minimumFieldLength =
  (
    form: FormInstance,
    fieldName: string,
    minimumLength: number,
    errorComponent?: (message: string) => JSX.Element,
    errorMessage?: string,
  ) =>
  (): Promise<void> => {
    const defaultErrorMessage = `${formatFieldName(
      fieldName,
    )} requires at least ${minimumLength} characters`;
    try {
      const fieldValue = form.getFieldValue(fieldName)?.trim();
      if (fieldValue.length < minimumLength) {
        return Promise.reject(
          generateFieldError(defaultErrorMessage, errorComponent, errorMessage),
        );
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(generateFieldError(defaultErrorMessage, errorComponent, errorMessage));
    }
  };

export const maximumFieldLength =
  (
    form: FormInstance,
    fieldName: string,
    maximumLength: number,
    errorComponent?: (message: string) => JSX.Element,
    errorMessage?: string,
  ) =>
  (): Promise<void> => {
    const defaultErrorMessage = `${formatFieldName(
      fieldName,
    )} can only be ${maximumLength} characters long`;
    try {
      const fieldValue = form.getFieldValue(fieldName)?.trim();
      if (fieldValue.length > maximumLength) {
        return Promise.reject(
          generateFieldError(defaultErrorMessage, errorComponent, errorMessage),
        );
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(generateFieldError(defaultErrorMessage, errorComponent, errorMessage));
    }
  };

export const invalidValues =
  (
    form: FormInstance,
    fieldName: string,
    invalidValues: Array<string>,
    errorMessage?: string,
    errorComponent?: (message: string) => JSX.Element,
  ) =>
  (): Promise<void> => {
    const defaultErrorMessage = `${formatFieldName(fieldName)} is invalid`;
    try {
      const fieldValue = form.getFieldValue(fieldName)?.trim();
      if (invalidValues.includes(fieldValue)) {
        return Promise.reject(
          generateFieldError(defaultErrorMessage, errorComponent, errorMessage),
        );
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(generateFieldError(defaultErrorMessage, errorComponent, errorMessage));
    }
  };

export const checkFullName =
  (
    form: FormInstance,
    fieldName: string,
    errorMessage: (message: string) => JSX.Element | string = (x) => x,
  ) =>
  (): Promise<void> => {
    const fullName = form.getFieldValue(fieldName)?.trim();
    if (!fullName) {
      return Promise.reject(errorMessage("Enter your name"));
    } else if (fullName.length > 40) {
      return Promise.reject(errorMessage("Maximum 40 characters"));
    }
    return Promise.resolve();
  };

export const checkPassword =
  (form: FormInstance, fieldName: string, errorMessage: (message: string) => JSX.Element) =>
  (): Promise<void> => {
    const password = form.getFieldValue(fieldName);
    if (!password) {
      return Promise.reject(errorMessage("Enter password"));
    } else if (password.length > 0 && password.length < 12) {
      return Promise.reject(errorMessage("Password must be at least 12 characters long"));
    } else if (!hasNumber(password)) {
      return Promise.reject(errorMessage("Password must contain at least 1 number"));
    } else if (!hasUppercase(password)) {
      return Promise.reject(errorMessage("Password must contain at least 1  capital letter"));
    } else if (!hasSpecialCharacter(password)) {
      return Promise.reject(errorMessage("Password must include a special character ex.!"));
    }
    return Promise.resolve();
  };

export const checkConfirmPassword =
  (
    form: FormInstance,
    fieldPassword: string,
    fieldConfirmPassword: string,
    errorMessage: (message: string) => JSX.Element,
  ) =>
  (): Promise<void> => {
    const password = form.getFieldValue(fieldPassword);
    const confirmPassword = form.getFieldValue(fieldConfirmPassword);

    if (!confirmPassword) {
      return Promise.reject(errorMessage("Re-enter password"));
    } else if (password === confirmPassword) {
      return Promise.resolve();
    }

    return Promise.reject(errorMessage("Password and confirm password do not match"));
  };

export const checkPhoneNumber =
  (form: FormInstance, fieldName: string, errorMessage: (message: string) => JSX.Element) =>
  (): Promise<void> => {
    const phone = form.getFieldValue(fieldName);

    if (!phone?.length) {
      return Promise.reject(errorMessage("Phone number is required"));
    } else {
      if (!isValidPhoneNumber(`+${phone}`)) {
        return Promise.reject(errorMessage("Invalid phone number"));
      }
    }
    return Promise.resolve();
  };
