import { KomiLogo } from "@komi-app/components";
import Button from "antd/lib/button";
import notification from "antd/lib/notification";
import Row from "antd/lib/row";
import classNames from "classnames";
import SignUpWithEmailForm from "components/Form/SignUpWithEmailForm";
import Modal from "components/Modal";
import SignIn from "components/SignIn";
import VerifyEmail from "components/VerifyEmail";
import { useRouter } from "next/router";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { ReactComponent as LeftIcon } from "public/static/assets/icons/left.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Experience } from "redux/Experience/types";
import { MODAL_CREATE_ACCOUNT, MODAL_WAITLIST, toggleModalActions } from "redux/Modal/actions";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { selectTalentBasicInfo } from "redux/Talent/selector";
import { Talent } from "redux/Talent/types";
import { setUserValueAction } from "redux/User/actions";
import { selectPhoneOTP, selectSignUpError, selectUserData, selectUserSocial } from "redux/User/selector";
import { PhoneOTP } from "redux/User/types";
import { getTalentName } from "utils/experience";
import { capitalizeFirstLetter } from "utils/string";
import { Paragraph } from "../Typography";

interface IProps {
  mask?: boolean;
  closable?: boolean;
  show: boolean;
  showEmail?: boolean;
  onOk(): void;
  onCancel(): void;
  showLoginModal(): void;
  title: string;
  description: string;
  talent?: Talent;
  experience?: Experience;
  isLandingPage?: boolean;
}

const CreateAccountModal: React.FC<IProps> = ({
  closable = true,
  mask = true,
  show,
  onOk,
  onCancel,
  showEmail,
  title,
  description,
  talent,
  isLandingPage = false,
  experience,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const user = useTypedSelector(selectUserData);
  const signUpError = useTypedSelector(selectSignUpError);
  const userSocial = useTypedSelector(selectUserSocial);
  const phoneOtp: PhoneOTP = useTypedSelector(selectPhoneOTP);

  const [registrationMethod, setRegistrationMethod] = useState("E-mail");
  const [email, setEmail] = useState<string | null>(null);
  const [step, setStep] = useState(showEmail ? 2 : 1);
  const [registerPayload, setRegisterPayload] = useState();
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));
  const talentInvitation = useTypedSelector(selectTalentBasicInfo);

  useEffect(() => {
    const { query } = router;
    const { talentProfileId, token, email, invitation, login_google, newUser } = query as unknown as {
      talentProfileId: string;
      token: string;
      email: string;
      invitation: string;
      login_google: boolean;
      newUser: string;
    };
    if (email && talentProfileId) {
      if (login_google && newUser === "true") {
        setStep(2);
      } else {
        setStep(1);
      }
    }

    return () => {
      if (invitation && email && token) {
        router.replace(window.location?.pathname || "/");
      }
    };
  }, [router]);

  const handleBack = useCallback(() => {
    setStep((currentStep) => currentStep - 1);
  }, []);

  const handleNext = useCallback(() => {
    setStep((currentStep) => currentStep + 1);
  }, []);

  const handleSignUpEmail = useCallback(
    (email: string) => {
      setEmail(email);
      handleNext();
    },
    [handleNext],
  );

  const handleCancel = useCallback(async () => {
    dispatch(
      setUserValueAction({
        signUpResult: null,
        userSocial: null,
        error: null,
        phoneOTP: null,
      }),
    );
    onCancel();
  }, [onCancel, dispatch]);
  const handleJoinWaitlist = useCallback(() => {
    dispatch(
      toggleModalActions({
        modal: MODAL_WAITLIST,
        status: true,
      }),
    );
  }, [dispatch]);

  const isCollaboratorInvitation = useMemo(() => router?.asPath?.startsWith("/invitation"), [router]);

  const isLogin = useMemo(() => {
    if (router.route === "/") return true;
    if (typeof window === "undefined") return false;

    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search);

    return params.has("login") || params.has("signin");
  }, [router]);

  const titleEl = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <div>
            <Paragraph preset="semibold20" className="m__b--4 create-account-modal-title">
              {isLandingPage ? `Access Talent Account` : `Log In or Sign Up for an Account`}
            </Paragraph>
            <Paragraph
              preset={isMobile ? "regular14" : "regular16"}
              className={`m__b--0 ${!isCollaboratorInvitation && "create-account-modal-sub-title"} opacity--08`}
            >
              {isLandingPage
                ? signUpModalData?.isApproved
                  ? "Sign up using your chosen method below to access your Komi account."
                  : isCollaboratorInvitation
                  ? `Sign up using your chosen method below to access ${getTalentName(
                      talentInvitation,
                      true,
                    )}’s Komi account.`
                  : `Log in to your Komi account to access your talent profile or any other profiles you’ve been invited to.`
                : `This site is powered by Komi. Create an account or log in to access your content or make purchases.`}
            </Paragraph>
          </div>
        );

      case 2:
        return (
          <div>
            <>
              <Paragraph preset={isMobile ? "semibold20" : "semibold20"} className="m__b--4 create-account-modal-title">
                {isLandingPage ? (
                  isCollaboratorInvitation || signUpModalData?.isApproved ? (
                    `You’re Nearly There!`
                  ) : (
                    <Row align="top" className={`${isMobile && "p__r--40"}`}>
                      <Button type="text" className="m__r--12 p--0 height--24 m__t--4" onClick={handleBack}>
                        <LeftIcon />
                      </Button>
                      <span className="flex--1">It Doesn’t Seem You Have an Account</span>
                    </Row>
                  )
                ) : (
                  `You’re Nearly There!`
                )}
              </Paragraph>
              <Paragraph
                preset="regular16"
                className={`m__b--0 opacity--08 ${isCollaboratorInvitation ? "" : "create-account-modal-sub-title"} ${
                  isLandingPage && !isCollaboratorInvitation && isMobile && "p__r--0"
                }`}
              >
                {isLandingPage ? (
                  isCollaboratorInvitation || signUpModalData?.isApproved ? (
                    `Please finishing signing up below to access ${getTalentName(
                      talentInvitation,
                      true,
                    )}’s Komi account.`
                  ) : signUpModalData?.isUser ? (
                    "Please sign in to accept your invite for your Komi account."
                  ) : (
                    <>
                      <span className="d--block m__b--16 opacity--08">
                        There is no account associated with the email address you’ve entered. At the moment Komi is
                        invite-only. Join waitlist below or ask a team member to invite you to their account.
                      </span>
                      <span className="opacity--08">
                        If you are having problems accessing your existing Komi account, contact{" "}
                      </span>
                      <a className="text--blue" href="mailto:support@komi.io">
                        support@komi.io
                      </a>
                      .
                    </>
                  )
                ) : (
                  `Create a Komi account to finish your purchase and enable access to any future purchases.`
                )}
              </Paragraph>
            </>
          </div>
        );
      case 4:
        return (
          <>
            <Paragraph
              preset={isMobile ? "semibold20" : "semibold20"}
              className={`m__b--4 create-account-modal-title ${isMobile && "p__r--40"}`}
            >
              We Can See You Have Been Added to the Waitlist
            </Paragraph>
            <Paragraph preset="regular16" className={`m__b--0 opacity--08 ${isMobile ? "" : "p__r--52"}`}>
              <span className="opacity--08">
                We are currently reviewing your request. If approved, onboarding instructions will be sent via email. If
                you have any questions in the meantime, reach out to{" "}
              </span>
              <a className="text--blue" href="mailto:support@komi.io">
                support@komi.io
              </a>
            </Paragraph>
          </>
        );
      default:
        break;
    }
  }, [
    step,
    isLandingPage,
    isCollaboratorInvitation,
    talentInvitation,
    handleBack,
    signUpModalData?.isApproved,
    signUpModalData?.isUser,
  ]);

  useEffect(() => {
    if (user && !isCollaboratorInvitation) {
      onOk();
    }
  }, [isCollaboratorInvitation, onOk, user]);

  useEffect(() => {
    if (signUpError) {
      notification.error({
        message: capitalizeFirstLetter(signUpError),
      });
    }
  }, [signUpError]);

  useEffect(() => {
    if (phoneOtp) {
      setStep(3);
    }
  }, [phoneOtp]);

  useEffect(() => {
    if ((userSocial || signUpModalData?.callback) && process.browser && !isCollaboratorInvitation) {
      setStep(2);
    }
  }, [isCollaboratorInvitation, signUpModalData?.callback, userSocial]);

  useEffect(() => {
    if (user && signUpModalData?.callback) {
      signUpModalData?.callback();
    }
  }, [signUpModalData, user]);

  return (
    <Modal
      maskStyle={
        isLandingPage
          ? { background: `rgb(18, 18, 18)` }
          : { background: `rgba(175, 175, 175, 0.8)`, backdropFilter: `blur(16px)` }
      }
      className={classNames("create-account-modal", {
        "home-page": isLandingPage,
        "collaborator-invitation": isLandingPage && !isLogin,
      })}
      closable={isLogin || (isLandingPage ? false : closable)}
      maskClosable={!isLandingPage}
      mask={mask}
      title={titleEl}
      visible={show}
      onOk={onOk}
      footer={
        isLogin ? (
          <>
            <Row align="middle" justify="center">
              <KomiLogo height={isMobile ? 20 : 24} />
            </Row>
          </>
        ) : !isLandingPage ? (
          <>
            <div className="divider"></div>
            <KomiLogo height={isMobile ? 20 : 24} />
          </>
        ) : (
          <></>
        )
      }
      onCancel={handleCancel}
      width={558}
      centered
      closeIcon={<CloseIcon />}
      destroyOnClose
    >
      {step === 1 && (
        <SignIn
          isHomePage={isLandingPage}
          //setRegistrationMethod={setRegistrationMethod}
          handleSignUpEmail={handleSignUpEmail}
          setStep={setStep}
        />
      )}
      {step === 2 &&
        (!isLandingPage || isCollaboratorInvitation || signUpModalData?.isApproved ? (
          <SignUpWithEmailForm
            isHomePage={isLandingPage}
            inputEmail={email}
            setRegisterPayload={setRegisterPayload}
            talent={talent}
            isLandingPage={isLandingPage}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : (
          <>
            <Button
              className={`btn-signin home-page`}
              type="primary"
              htmlType="submit"
              block
              onClick={handleJoinWaitlist}
            >
              Join Waitlist
            </Button>
          </>
        ))}
      {step === 3 && <VerifyEmail registerPayload={registerPayload} />}
      {step === 4 && (
        <Button className={`btn-signin home-page`} type="primary" htmlType="submit" block onClick={onCancel}>
          Done
        </Button>
      )}
    </Modal>
  );
};

export default CreateAccountModal;
