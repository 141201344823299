import AntdSelect from "antd/lib/select";
import { SelectProps } from "antd/lib/select";
import { ReactComponent as DownIcon } from "public/static/assets/icons/down.svg";
import React, { ReactNode } from "react";

interface IProps extends SelectProps<any> {
  children: ReactNode;
}

const { Option } = AntdSelect;

const Select = ({ children, ...props }: IProps) => {
  return (
    <AntdSelect {...props} suffixIcon={<DownIcon />}>
      {children}
    </AntdSelect>
  );
};

export { Option, Select };
