import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import { Text } from "components/Typography";
import { ROLES } from "constants/auth";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/rootReducer";
import { acceptCollaboratorInviteActions, sendOTPActions, signUpActions } from "redux/User/actions";
import { selectSendOtpError, selectUserLoading, selectVerifyOtpError } from "redux/User/selector";
import { KOMI_USER_LOCATION } from "services/UserService";
import { komiTalentUrl } from "services/DomainService";
import { AnalyticServices } from "utils/analytics";
import notification from "utils/notification";

interface IProps {
  registerPayload: any;
}

const VerifyEmail: React.FC<IProps> = ({ registerPayload }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const loading = useTypedSelector(selectUserLoading);
  const error = useTypedSelector(selectVerifyOtpError);
  const errorSend = useTypedSelector(selectSendOtpError);

  const [otp, setOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [resendCountdown, setResendCountDown] = useState(60);

  const handleChange = useCallback(
    (e) => {
      setOtp(e.target.value?.trim());
    },
    [setOtp],
  );

  const sendSegmentEvent = useCallback((data: any) => {
    AnalyticServices.track(SEGMENT_EVENT.SIGN_UP, {
      ...data,
      "Registration Method": "E-mail",
      Location: Cookies.get(KOMI_USER_LOCATION),
      Platform: isMobile ? "Responsive" : "Web",
    });
  }, []);

  const isCollaboratorInvitation = useMemo(() => router?.asPath?.startsWith("/invitation"), [router]);

  const acceptInvitation = useCallback(() => {
    if (isCollaboratorInvitation) {
      dispatch(
        acceptCollaboratorInviteActions.REQUEST({
          form: {
            email: router?.query?.email,
            token: router?.query?.token,
          },
          refreshUser: true,
          onSuccess: () => router.push(`${komiTalentUrl}/collaborator-login?talentProfileId=${router?.query?.talentProfileId}`),
          onAlreadyJoined: () => router.push(`${komiTalentUrl}/collaborator-login?talentProfileId=${router?.query?.talentProfileId}`),
        }),
      );
    }
  }, [dispatch, router, isCollaboratorInvitation]);

  const handleContinue = useCallback(() => {
    dispatch(
      signUpActions.REQUEST({
        form: {
          ...registerPayload,
          emailVerifyOTP: otp,
        },
        sendSegmentEvent: sendSegmentEvent,
        callback: () => acceptInvitation(),
      }),
    );
  }, [acceptInvitation, dispatch, otp, registerPayload, sendSegmentEvent]);

  const handleResend = useCallback(() => {
    if (resendCountdown <= 0 && resendCount < 4) {
      setResendCount((val) => val + 1);
      setResendCountDown(60);
      dispatch(
        sendOTPActions.REQUEST({
          email: registerPayload.email,
          firstName: registerPayload.firstName || registerPayload.lastName,
          role: isCollaboratorInvitation ? ROLES.TALENT : ROLES.USER,
        }),
      );
    }
  }, [resendCountdown, resendCount, dispatch, registerPayload, isCollaboratorInvitation]);

  useEffect(() => {
    const id = setInterval(() => {
      setResendCountDown((c) => (c >= 0 ? c - 1 : c));
    }, 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({ message: error });
    }
  }, [error]);

  useEffect(() => {
    if (errorSend) {
      notification.error({ message: errorSend });
    }
  }, [errorSend]);

  return (
    <Form className="verify-otp p__t--32">
      <Row>
        <Text preset="semibold24">Verify your email</Text>
      </Row>
      <Row className="m__b--32 m__t--4">
        <Text
          preset="regular16"
          className="opacity--08"
        >{`Enter the verification code sent to ${registerPayload?.email}`}</Text>
      </Row>
      <Form.Item className="m__t--24">
        <Input type="number" placeholder="Enter code" onChange={handleChange} />
      </Form.Item>
      <Row className="m__t--24 opacity--08" justify="center" align="middle">
        {resendCount === 4 ? (
          <Text preset="regular16" className="text__align--center">
            <span>You have reached your max verification code sends, please</span>
            <a className="font__weight--medium" href="mailto:support@komi.io">
              {" "}
              contact support
            </a>
            <span> and we can help you further</span>
          </Text>
        ) : (
          <>
            <Text preset={isMobile ? "regular14" : "regular16"}>Not in inbox or spam folder?</Text>{" "}
            <Button type="text" className="p--0 m__l--4" onClick={handleResend}>
              <Text preset="semibold16" className={resendCountdown <= 0 ? "text--blue" : ""}>
                {resendCountdown > 0 ? `Resend in ${resendCountdown}s` : "Resend"}
              </Text>
            </Button>
          </>
        )}
      </Row>
      <Row className="m__t--24" justify="center">
        <Button disabled={otp?.length !== 6} type="primary" block onClick={handleContinue} loading={loading}>
          Verify
        </Button>
      </Row>
    </Form>
  );
};

export default VerifyEmail;
