import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Form from "antd/lib/form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import classNames from "classnames";
import DatePicker from "components/DatePicker";
import { Text } from "components/Typography";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { ReactComponent as WarningIcon } from "public/static/assets/icons/warning.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { acceptCollaboratorInviteActions, sendOTPActions, signUpActions } from "redux/User/actions";
import { selectSendOtpError, selectUserLoading, selectUserSocial } from "redux/User/selector";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { getTalentName } from "utils/experience";
import notification from "utils/notification";
import { SEGMENT_EVENT } from "../../constants/segment";
import { selectModalData } from "redux/Modal/selectors";
import { MODAL_CREATE_ACCOUNT } from "redux/Modal/actions";
import { ROLES } from "constants/auth";
import { checkConfirmPassword, checkFullName, checkPassword, checkPhoneNumber } from "utils/validators";
import { komiConsumerUrl } from "services/DomainService";

interface IProps {
  isHomePage: boolean;
  handleNext(): void;
  handleBack(): void;
  talent?: Talent;
  setRegisterPayload: (payload: any) => void;
  isLandingPage?: boolean;
  inputEmail: string | null;
}

const SignUpWithEmailForm: React.FC<IProps> = ({
  handleBack,
  talent,
  setRegisterPayload,
  isLandingPage,
  inputEmail,
  isHomePage,
}) => {
  const router = useRouter();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = screens["xs"];

  const userSocial = useTypedSelector(selectUserSocial);
  const loading = useTypedSelector(selectUserLoading);
  const error = useTypedSelector(selectSendOtpError);
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));

  const [isDisabledEmail, setIsDisabledEmail] = useState(false);
  const [isInvitation, setIsInvitation] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isValidBirthDay, setIsValidBirthday] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const { query } = router;
    const { token, email, invitation } = query as unknown as {
      token: string;
      email: string;
      invitation: string;
    };
    //KC: When I am checking the bug for KOMI-584, it seems this if statement here is always false
    //Not sure if it is intended or not...
    if (invitation && email && token) {
      setIsDisabledEmail(true);
      setIsInvitation(true);
      form.setFieldsValue({
        email: router.query?.email,
        token: router.query?.token,
      });
    } else if (inputEmail) {
      form.setFieldsValue({ email: inputEmail });
    }

    return () => {
      if (invitation && email && token) {
        setIsDisabledEmail(true);
        setIsInvitation(true);
        form.setFieldsValue({
          email: "",
          token: "",
        });
      }
    };
  }, [router, form, inputEmail]);

  const sendSegmentEvent = useCallback(
    (data: any) => {
      AnalyticServices.track(SEGMENT_EVENT.SIGN_UP, {
        "Registration Method": userSocial.provider,
        Location: Cookies.get(KOMI_USER_LOCATION),
        Platform: isMobile ? "Responsive" : "Web",
        ...data,
      });
    },
    [userSocial],
  );

  const isCollaboratorInvitation = useMemo(() => router?.asPath?.startsWith("/invitation"), [router]);

  const acceptInvitation = useCallback(() => {
    if (isCollaboratorInvitation) {
      dispatch(
        acceptCollaboratorInviteActions.REQUEST({
          form: {
            email: router?.query?.email,
            token: router?.query?.token,
          },
          refreshUser: true,
          onSuccess: () => router.push("/"),
          onAlreadyJoined: () => router.push("/"),
        }),
      );
    }
  }, [dispatch, router, isCollaboratorInvitation]);

  const handleRedirectToFinishSetupPage = useCallback(() => {
    if (router.pathname === "/talent-register") {
      router.replace(`/finish-setup?waitlistApplicationToken=${router?.query?.token || ""}`);
    }
  }, [router]);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (!isValidBirthDay) {
        setIsError(true);
        return;
      }
      const birthday = form.getFieldValue("birthday");
      const termAndMarketing = form.getFieldValue("termAndMarketing");
      const email = form.getFieldValue("email") || "";
      const fullName = form.getFieldValue("fullName")?.trim();
      const firstName = fullName?.split(" ")?.slice(0, -1)?.join(" ");
      const lastName = fullName?.split(" ")?.slice(-1)?.join(" ") || "";
      const isTalent = signUpModalData?.waitlistApplicationToken || isCollaboratorInvitation;
      const payload: any = {
        firstName: firstName,
        lastName,
        email: email || signUpModalData?.email || "",
        password: form.getFieldValue("password"),
        phone: form.getFieldValue("phone"),
        dateOfBirth: new Date(Date.UTC(birthday.year, birthday.month, birthday.day)).toISOString(),
        waitlistApplicationToken: signUpModalData?.waitlistApplicationToken || "",
        role: isTalent ? ROLES.TALENT : ROLES.USER,
        allowedMarketingEmailTalents:
          talent && termAndMarketing?.includes("acceptMarketing")
            ? [
                {
                  id: talent?.id,
                },
              ]
            : [],
      };

      if (isInvitation) {
        payload.token = form.getFieldValue("token");
        payload.isInvitation = isInvitation;
      }

      if (userSocial || signUpModalData?.waitlistApplicationToken) {
        payload.authProviderId = userSocial?.authProviderId || "";
        dispatch(
          signUpActions.REQUEST({
            form: payload,
            sendSegmentEvent: sendSegmentEvent,
            callback: () =>
              signUpModalData?.waitlistApplicationToken ? handleRedirectToFinishSetupPage() : acceptInvitation(),
          }),
        );
      } else {
        setRegisterPayload(payload);
        dispatch(
          sendOTPActions.REQUEST({
            email,
            firstName: firstName || fullName,
            role: isCollaboratorInvitation ? ROLES.TALENT : ROLES.USER,
          }),
        );
      }
    });
  };

  const checkTerms = () => {
    const termAndMarketing = form.getFieldValue("termAndMarketing");

    if (!termAndMarketing?.includes("acceptTerm")) {
      return Promise.reject(
        errorMessage(
          "In order to continue you must first accept Komi’s terms of service and acknowledge Komi’s privacy policy",
        ),
      );
    }
    return Promise.resolve();
  };

  const checkDisabled = useCallback(() => {
    const values = form.getFieldsValue();
    setIsDisabled(
      !(
        values.email &&
        values.password &&
        values.firstName &&
        values.lastName &&
        values.phone &&
        values?.termAndMarketing?.includes("acceptTerm") &&
        values.birthday
      ),
    );
  }, [form]);

  useEffect(() => {
    checkDisabled();
  }, [form, checkDisabled]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
      });
    }
  }, [error]);

  const errorMessage = useCallback(
    (message: string) => {
      return (
        <Row align="top">
          <WarningIcon />
          <Text
            preset={mobile ? "regular12" : "regular14"}
            className={classNames("d--block text--red m__l--4 flex--1", mobile && "pt-3")}
          >
            {message}
          </Text>
        </Row>
      );
    },
    [mobile],
  );

  const termOptions = useMemo(() => {
    const terms: any = [
      {
        label: (
          <Text preset="regular14">
            <span>I agree to Komi’s </span>
            <a
              href={`${komiConsumerUrl}/${isLandingPage ? "talent" : "consumer"}-terms`}
              target="_blank"
              rel="noreferrer"
            >
              {isLandingPage ? `talent terms` : `terms of service`}
            </a>
            <span> and acknowledge Komi’s </span>
            <a href={`${komiConsumerUrl}/privacy-policy`} target="_blank" rel="noreferrer">
              privacy policy
            </a>
          </Text>
        ),
        value: "acceptTerm",
      },
    ];

    if (talent) {
      terms.push({
        label: (
          <Text preset="regular14">{`I would like to occasionally receive special offers, promotions and news from ${getTalentName(
            talent,
          )}`}</Text>
        ),
        value: "acceptMarketing",
      });
    }
    return terms;
  }, [isLandingPage, talent]);

  useEffect(() => {
    if (talent) {
      form.setFieldsValue({
        termAndMarketing: ["acceptMarketing"],
      });
    }
  }, [form, talent]);

  useEffect(() => {
    if (userSocial) {
      form.setFieldsValue({
        email: userSocial?.email,
      });
    }
  }, [form, userSocial]);

  return (
    <Form
      hideRequiredMark
      layout="vertical"
      scrollToFirstError
      form={form}
      onFinish={handleSubmit}
      onFieldsChange={() => {
        checkDisabled();
      }}
    >
      <Form.Item
        label="Full Name"
        name="fullName"
        rules={[{ validator: checkFullName(form, "fullName", errorMessage) }]}
      >
        <Input placeholder="What’s your full name?" />
      </Form.Item>
      {userSocial ? (
        <>
          <Form.Item
            label="Phone number"
            name="phone"
            rules={[{ validator: checkPhoneNumber(form, "phone", errorMessage) }]}
          >
            <PhoneInput
              country="us"
              buttonClass="phone-button-select"
              inputClass="phone-input"
              placeholder="Enter phone number"
              onFocus={(e) => {
                e.currentTarget.scrollIntoView();
              }}
            />
          </Form.Item>
          <DatePicker
            form={form}
            errorMessage={errorMessage}
            onValidate={setIsValidBirthday}
            isError={isError}
            onChangeError={setIsError}
          />
          <Form.Item
            label="Email"
            name="email"
            className={isHomePage ? "d--none" : ""}
            rules={[
              {
                type: "email",
                message: errorMessage("Invalid email address format"),
              },
              {
                required: true,
                message: errorMessage("Email is required"),
              },
            ]}
          >
            <Input disabled={!!userSocial?.email} placeholder="Your email" />
          </Form.Item>
        </>
      ) : (
        <>
          {isInvitation && (
            <Form.Item hidden label="Token" name="token">
              <Input />
            </Form.Item>
          )}
          {!isHomePage && (
            <Form.Item
              label="Email"
              name="email"
              className={isHomePage ? "d--none" : ""}
              rules={[
                {
                  type: "email",
                  message: errorMessage("Invalid email address format"),
                },
                {
                  required: true,
                  message: errorMessage("Email is required"),
                },
              ]}
            >
              <Input disabled={isDisabledEmail} placeholder="Your email" />
            </Form.Item>
          )}
          <Form.Item
            label="Phone number"
            name="phone"
            rules={[{ validator: checkPhoneNumber(form, "phone", errorMessage) }]}
          >
            <PhoneInput
              country="us"
              buttonClass="phone-button-select"
              inputClass="phone-input"
              placeholder="Enter phone number"
            />
          </Form.Item>
          <Form.Item
            label="Set a password"
            name="password"
            rules={[{ validator: checkPassword(form, "password", errorMessage) }]}
          >
            <Input.Password placeholder="Enter 12 characters or more" visibilityToggle={false} />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[{ validator: checkConfirmPassword(form, "password", "confirmPassword", errorMessage) }]}
          >
            <Input.Password placeholder="Re-enter your password" visibilityToggle={false} />
          </Form.Item>
          <DatePicker
            form={form}
            errorMessage={errorMessage}
            onValidate={setIsValidBirthday}
            isError={isError}
            onChangeError={setIsError}
          />
        </>
      )}

      <Form.Item name="termAndMarketing" rules={[{ validator: checkTerms }]}>
        <Checkbox.Group
          className={classNames({
            "term-checkbox": true,
            "term-checkbox--single": termOptions?.length === 1,
          })}
          options={termOptions}
        />
      </Form.Item>
      <Form.Item className={isMobile ? "m__t--30" : "m__t--24"}>
        <Button
          className={`btn-sign-up ant-btn-md ${isHomePage ? "home-page" : ""}`}
          type="primary"
          htmlType="submit"
          block
          loading={loading}
        >
          Sign up
        </Button>
      </Form.Item>
      {!userSocial && !isInvitation && !signUpModalData?.callback && (
        <Row align="middle" justify="center">
          <Button className="ant-btn-back" block size="large" loading={loading} onClick={handleBack}>
            Go back
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default SignUpWithEmailForm;
