import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import React, { useCallback, useEffect, useState } from "react";
import { Text } from "components/Typography";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/rootReducer";
import { selectForgotPasswordError, selectForgotPasswordSuccess, selectUserLoading } from "redux/User/selector";
import notification from "utils/notification";
import { forgotPasswordActions } from "redux/User/actions";
import { Row } from "antd/lib/grid";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { ReactComponent as WarningIcon } from "public/static/assets/icons/warning.svg";

interface IProps {
  email?: string;
  isHomePage?: boolean;
  onCancel: () => void;
}

const ForgotPasswordForm: React.FC<IProps> = ({ email, isHomePage, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const forgotPasswordSuccess = useTypedSelector(selectForgotPasswordSuccess);
  const forgotPasswordError = useTypedSelector(selectForgotPasswordError);
  const loading = useTypedSelector(selectUserLoading);
  const [hasSubmit, setHasSubmit] = useState(false);

  useEffect(() => {
    if (forgotPasswordError && hasSubmit) {
      notification.error({
        message: "We have no record of that email in our system",
      });
    }
  }, [forgotPasswordError, hasSubmit]);

  useEffect(() => {
    if (forgotPasswordSuccess && hasSubmit) {
      notification.success({
        message: "Your password reset request has been sent. Check your email for further instructions.",
      });
      onCancel();
    }
  }, [forgotPasswordSuccess, hasSubmit, onCancel]);

  const handleSubmitForgotPasswordEmail = React.useCallback(() => {
    const email = form.getFieldValue("email");
    if (!email) {
      return notification.error({
        message: "Please enter your email address.",
      });
    }
    dispatch(
      forgotPasswordActions.REQUEST({
        email,
      }),
    );
    setHasSubmit(true);
  }, [dispatch, form]);

  const errorMessage = useCallback((message: string) => {
    return (
      <Row align="top">
        <WarningIcon />
        <Text
          preset={isMobile ? "regular12" : "regular14"}
          className={classNames("d--block text--red m__l--4 flex--1", isMobile && "pt-3")}
        >
          {message}
        </Text>
      </Row>
    );
  }, []);

  return (
    <React.Fragment>
      <Form
        layout="vertical"
        hideRequiredMark
        scrollToFirstError
        initialValues={{ email }}
        form={form}
        onFinish={handleSubmitForgotPasswordEmail}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: errorMessage("Invalid email address format"),
            },
            {
              required: true,
              message: errorMessage("Please enter your email address"),
            },
          ]}
        >
          <Input className="input-rounded" disabled={!!email} placeholder="Enter your email address" />
        </Form.Item>

        <Form.Item className="m__t--16">
          <Button className={`${isHomePage && "home-page"}`} type="primary" htmlType="submit" block loading={loading}>
            Reset password
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default ForgotPasswordForm;
