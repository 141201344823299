import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import classNames from "classnames";
import LoginApple from "components/LoginApple";
import LoginFacebook from "components/LoginFacebook";
import LoginGoogle from "components/LoginGoogle";
import { useRouter } from "next/router";
import { ReactComponent as RightIcon } from "public/static/assets/icons/sRight.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MODAL_LOGIN, toggleModalActions } from "redux/Modal/actions";
import { useTypedSelector } from "redux/rootReducer";
import { checkUserExistedActions } from "redux/User/actions";
import {
  selectUserError,
  selectUserExisted,
  selectUserInWaitlist,
  selectUserLoading,
} from "redux/User/selector";
import notification from "utils/notification";
import { validateEmail } from "utils/string";
import { ROLES } from "constants/auth";
import { komiConsumerUrl } from "services/DomainService";
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

interface IProps {
  isHomePage: boolean;
  handleSignUpEmail(email: string): void;
  //setRegistrationMethod(method: string): void;
  setStep(step: number): void;
}

const SignIn: React.FC<IProps> = ({
  handleSignUpEmail,
  //setRegistrationMethod,
  isHomePage,
  setStep,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const isExisted = useTypedSelector(selectUserExisted);
  const isInWaitlist = useTypedSelector(selectUserInWaitlist);
  const loading = useTypedSelector(selectUserLoading);
  const error = useTypedSelector(selectUserError);

  const [email, setEmail] = useState<string | undefined>(undefined);

  const showSignInModal = useCallback(() => {
    dispatch(
      toggleModalActions({
        modal: MODAL_LOGIN,
        status: true,
        data: {
          email,
        },
      }),
    );
  }, [dispatch, email]);

  const handleSignupWithEmail = useCallback(() => {
    if (email) {
      handleSignUpEmail(email);
    }
  }, [email, handleSignUpEmail]);

  const isCollaboratorInvitation = useMemo(
    () => router?.asPath?.startsWith("/invitation"),
    [router],
  );

  const handleNext = useCallback(() => {
    if (isExisted) {
      showSignInModal();
    } else {
      if (error) {
        dispatch(
          checkUserExistedActions.REQUEST({
            email,
            onSuccess: () => showSignInModal(),
            onError: () =>
              notification.error({
                message: error,
              }),
          }),
        );
      } else {
        if (isHomePage && !isCollaboratorInvitation && isInWaitlist) {
          setStep(2);
        } else {
          handleSignupWithEmail();
        }
      }
    }
  }, [
    dispatch,
    email,
    error,
    handleSignupWithEmail,
    isCollaboratorInvitation,
    isExisted,
    isHomePage,
    isInWaitlist,
    setStep,
    showSignInModal,
  ]);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (!loading && email?.length && validateEmail(email || "") && e.key === "Enter") {
        handleNext();
      }
    },
    [email, handleNext, loading],
  );

  useEffect(() => {
    if (email?.length && validateEmail(email || "")) {
      dispatch(
        checkUserExistedActions.REQUEST({
          email,
        }),
      );
    }
  }, [dispatch, email]);

  useEffect(() => {
    if (isExisted && isCollaboratorInvitation) {
      handleNext();
    }
  }, [handleNext, isCollaboratorInvitation, isExisted]);

  useEffect(() => {
    const { query } = router;
    const { email } = query as unknown as {
      email: string;
    };
    if (email?.length) {
      setEmail(email);
    }
  }, [router]);

  const isDisable = useMemo(
    () => isHomePage && (router?.query?.email?.length || 0) > 0,
    [isHomePage, router?.query?.email?.length],
  );

  return (
    <div>
      {/*!isHomePage && (
        <LoginFacebook
          title="Continue with Facebook"
          setRegistrationMethod={() => setRegistrationMethod("Facebook")}
          className="m__t--0"
        />
      )*/}
      <LoginGoogle
        login_hint={router?.query?.email as string}
        className={isHomePage ? "m__t--0" : ""}
        title="Continue with Google"
      />

      {/*!isHomePage && <LoginApple title="Continue with Apple" />*/}
      <div className="signin-divider">
        <span className="divider"></span>
        <span>or continue with email</span>
        <span className="divider"></span>
      </div>
      <div
        className={classNames({ "input-email__wrapper": true, active: (email?.length || 0) > 0 })}
      >
        <Input
          autoFocus
          suffix={
            (email?.length || 0) > 0 ? (
              loading ? (
                <Spin spinning={true} indicator={antIcon} />
              ) : (
                <Button
                  className={isHomePage ? "home-page" : ""}
                  shape="circle"
                  disabled={!validateEmail(email || "")}
                  onClick={handleNext}
                >
                  <RightIcon />
                </Button>
              )
            ) : (
              <></>
            )
          }
          disabled={isDisable}
          className="input-email text__align--center"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={onChangeEmail}
          placeholder="Enter your email"
        />
      </div>
    </div>
  );
};

export default SignIn;
