export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const addingHttpsPrefix = (str: string) => {
  let result = str;

  if (result?.length === 0 || !result) return result;

  if (str.substring(0, 7) === "http://") result = "https://" + str.slice(7, str.length);
  else if (str.substring(0, 8) !== "https://") result = "https://" + str;
  return result;
};

export const IsJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const hasNumber = (str: string) => {
  return /\d/.test(str);
};

export const hasUppercase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const hasSpecialCharacter = (str: string) => {
  return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
};

export const formatInstagramUrl = (str = "") => {
  if (str?.[0] === "@") {
    str = str.substring(1);
  }

  return `https://www.instagram.com/${str}`;
};

export const formatUrl = (str: string) => {
  if (!str?.match(/^[a-zA-Z]+:\/\//)) {
    return "http://" + str;
  }

  return str;
};

export const convertToUrl = (str = "") => {
  return str
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^a-zA-Z0-9 ]/g, "")
    ?.replace(/\s+/g, "-");
};
export const stringReplaceBetween = (str: string, replace = "...", start = 10, end = 10) => {
  return str.substring(0, start) + replace + str.substring(end);
};
