import Button from "antd/lib/button";
import Form from "antd/lib/form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import notification from "antd/lib/notification";
import Row from "antd/lib/row";
import classNames from "classnames";
import Modal from "components/Modal";
import { useRouter } from "next/router";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { ReactComponent as LeftIcon } from "public/static/assets/icons/left.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Experience } from "redux/Experience/types";
import { MODAL_LOGIN } from "redux/Modal/actions";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { getUserByIdActions, setUserValueAction } from "redux/User/actions";
import {
  selectResetPasswordSuccess,
  selectSignInError,
  selectUserAuthProviders,
  selectUserData,
  selectUserLoading,
} from "redux/User/selector";
import { getTalentName } from "utils/experience";
import { Paragraph, Text } from "../Typography";
import { selectTalentBasicInfo } from "redux/Talent/selector";
import { LogInForm } from "components/LogInForm/LogInForm";
import { KomiLogo } from "@komi-app/components";

interface IProps {
  mask?: boolean;
  closable?: boolean;
  show: boolean;
  onOk(): void;
  onCancel(): void;
  showCreateAccountModal(): void;
  isLandingPage?: boolean;
  talent?: Talent;
  experience?: Experience;
}

enum ForgotPasswordStep {
  None = 0,
  Email,
  Reset,
  Done,
}

const LoginModal: React.FC<IProps> = ({
  closable = true,
  mask = true,
  show,
  onOk,
  onCancel,
  showCreateAccountModal,
  isLandingPage,
  talent,
  experience,
}) => {
  const router = useRouter();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isMobile = screens["xs"];

  const user = useTypedSelector(selectUserData);
  const signInError = useTypedSelector(selectSignInError);
  const loading = useTypedSelector(selectUserLoading);
  const resetPasswordSuccess = useTypedSelector(selectResetPasswordSuccess);
  const loginModalData = useTypedSelector((state) => selectModalData(state, MODAL_LOGIN));
  const authProviders = useTypedSelector(selectUserAuthProviders);
  const talentInvitation = useTypedSelector(selectTalentBasicInfo);

  const [forgotPasswordStep, setForgotPasswordStep] = useState(ForgotPasswordStep.None);

  const isLoginGoogle = useMemo(() => {
    return (authProviders || [])?.includes("google") || (loginModalData?.authProviders || [])?.includes("google");
  }, [authProviders, loginModalData?.authProviders]);

  useEffect(() => {
    if (loginModalData?.email) {
      form.setFieldsValue({ email: loginModalData?.email });
    } else if (router.query?.email) {
      form.setFieldsValue({ email: router.query?.email });
    }
  }, [router, form, loginModalData?.email]);

  useEffect(() => {
    if (user) {
      onOk();
    }
  }, [onOk, user]);

  useEffect(() => {
    if (signInError) {
      notification.error({
        message: signInError || "Your email and/or password do not match.",
      });
    }
  }, [signInError]);

  const isCollaboratorInvitation = useMemo(() => router?.asPath?.startsWith("/invitation"), [router]);

  const handleCancel = useCallback(async () => {
    dispatch(
      setUserValueAction({
        signUpResult: null,
        userSocial: null,
        error: null,
        phoneOTP: null,
      }),
    );
    onCancel();
  }, [onCancel, dispatch]);

  useEffect(() => {
    if (!show) {
      setForgotPasswordStep(ForgotPasswordStep.None);
    }
  }, [show]);

  const handleForgotPasswordBack = React.useCallback(() => {
    setForgotPasswordStep(ForgotPasswordStep.None);
  }, []);

  const titleEl = useMemo(() => {
    return (
      <div>
        <Paragraph preset="semibold20" className={`m__b--4 create-account-modal-title ${isMobile && "p__r--40"}`}>
          {isLandingPage ? (
            loginModalData?.isTalent ? (
              `Your Invitation Has Already Been Accepted`
            ) : isLoginGoogle ? (
              `Seems Like You Signed Up With Google`
            ) : isCollaboratorInvitation ? (
              `Seems Like You Already Have a Komi Account`
            ) : loginModalData?.isUser ? (
              "Seems Like You Already Have a Komi Account"
            ) : (
              <Row align="middle">
                <Button type="text" className="m__r--12 p--0 height--24" onClick={showCreateAccountModal}>
                  <LeftIcon />
                </Button>
                <span>Log In to Your Account</span>
              </Row>
            )
          ) : (
            `Seem like you already have a Komi account`
          )}
        </Paragraph>
        <Paragraph
          preset={isMobile ? "regular14" : "regular16"}
          className={`m__b--0 ${isCollaboratorInvitation ? "" : "create-account-modal-sub-title"} ${loginModalData?.isTalent || loginModalData?.isUser ? "user-existed" : ""
            } opacity--08`}
        >
          {isLandingPage ? (
            loginModalData?.isTalent ? (
              <>
                Seems like you have already accepted this invitation and signed up for a Komi account. Please log into
                your account below. If you have issues logging in contact{" "}
                <a href="mailto:support@komi.io" className="text--blue">
                  support@komi.io
                </a>
                .
              </>
            ) : loginModalData?.isUser ? (
              loginModalData?.role ? "Log in to your Komi account to access your talent profile or any other profiles you’ve been invited to." : "Please sign in to accept your invite for your Komi account."
            ) : isCollaboratorInvitation ? (
              `Please sign in to accept your invite for ${getTalentName(talentInvitation, true)}’s Komi account.`
            ) : (
              `Log in to your Komi account to access your talent profile or any other profiles you’ve been invited to `
            )
          ) : (
            `To finish your purchase and access purchased content, please login to your Komi account below.`
          )}
        </Paragraph>
      </div>
    );
  }, [
    isCollaboratorInvitation,
    isLandingPage,
    isLoginGoogle,
    isMobile,
    showCreateAccountModal,
    talentInvitation,
    loginModalData?.isTalent,
    loginModalData?.isUser,
  ]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(getUserByIdActions.REQUEST());
    }
  }, [dispatch, resetPasswordSuccess]);

  const isForgotPassword = useMemo(() => forgotPasswordStep !== ForgotPasswordStep.None, [forgotPasswordStep]);

  let modalTitle: any = "Sign in";
  switch (forgotPasswordStep) {
    case ForgotPasswordStep.Email:
      modalTitle = (
        <Paragraph preset="semibold20">
          <Row align="middle">
            <Button type="text" className="m__r--12 p--0 height--24" onClick={handleForgotPasswordBack}>
              <LeftIcon />
            </Button>
            <span>Forgot Password</span>
          </Row>
        </Paragraph>
      );
      break;
    case ForgotPasswordStep.Reset:
      modalTitle = "Reset Your Password";
      break;
    case ForgotPasswordStep.Done:
      modalTitle = "";
      break;
  }

  return (
    <Modal
      maskStyle={
        isLandingPage
          ? { background: `rgb(18, 18, 18)` }
          : { background: `rgba(175, 175, 175, 0.8)`, backdropFilter: `blur(16px)` }
      }
      className={classNames("login-modal", {
        "home-page": isLandingPage,
        "collaborator-invitation": isCollaboratorInvitation,
        "forgot-password": isForgotPassword,
      })}
      closable={isLandingPage || closable}
      maskClosable={!isLandingPage}
      title={
        forgotPasswordStep === ForgotPasswordStep.None ? (
          titleEl
        ) : (
          <Text preset={isMobile ? "semibold20" : "semibold24"}>{modalTitle}</Text>
        )
      }
      visible={show}
      onOk={onOk}
      onCancel={handleCancel}
      footer={
        isLandingPage ? (
          <Row align="middle" justify="center">
            <KomiLogo height={isMobile ? 20 : 24} />
          </Row>
        ) : (
          <>
            <div className="divider"></div>
            <KomiLogo height={isMobile ? 20 : 24} />
          </>
        )
      }
      closeIcon={<CloseIcon />}
      centered
    >
      <LogInForm
        email={loginModalData?.email}
        onOk={onOk}
        showCreateAccountModal={showCreateAccountModal}
        show={show}
        isLandingPage={isLandingPage}
        user={user}
        signInError={signInError}
        loading={loading}
        resetPasswordSuccess={resetPasswordSuccess}
        isLoginGoogle={isLoginGoogle}
      />
    </Modal>
  );
};

export default LoginModal;
