import Button from "antd/lib/button";
import Form from "antd/lib/form";
import { Col } from "antd/lib/grid";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";
import Row from "antd/lib/row";
import classNames from "classnames";
import ForgotPasswordForm from "components/Form/ForgotPasswordForm";
import { SEGMENT_EVENT } from "constants/segment";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  MODAL_TWO_FACTOR,
  MODAL_SIGNUP_ACTIVATE,
  MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_LOGIN,
  SELECT_METHOD,
  toggleModalActions
} from "redux/Modal/actions";
import {
  acceptCollaboratorInviteActions,
  checkUserExistedActions,
  getUserByIdActions,
  requestOtpActions,
  resetPasswordActions,
  signInActions,
  talentLoginActions,
} from "redux/User/actions";
import { AnalyticServices } from "utils/analytics";
import { Text } from "../Typography";
import { ReactComponent as WarningIcon } from "public/static/assets/icons/warning.svg";
import LoginGoogle from "components/LoginGoogle";
import Cookies from "js-cookie";
import { KOMI_USER_LOCATION } from "services/UserService";
import { otpRequest, PHONE } from "components/TwoFactorModal";
import { RequestOtpRequest } from "redux/User/types";
import { komiConsumerUrl } from "services/DomainService";

enum ForgotPasswordStep {
  None = 0,
  Email,
  Reset,
  Done,
}

interface LogInFormProps {
  email?: string;
  onOk: () => void;
  showCreateAccountModal: () => void;
  show: boolean;
  isLandingPage?: boolean;
  user: any;
  signInError: any;
  loading: any;
  resetPasswordSuccess: boolean;
  isLoginGoogle: boolean;
}

export const LogInForm: React.FC<LogInFormProps> = ({
  email,
  onOk,
  showCreateAccountModal,
  show,
  isLandingPage,
  user,
  signInError,
  loading,
  resetPasswordSuccess,
  isLoginGoogle,
}) => {
  const router = useRouter();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isMobile = screens["xs"];

  const [forgotPasswordStep, setForgotPasswordStep] = useState(ForgotPasswordStep.None);

  const isTalentLogin = useMemo(() => {
    return (
      window.location.origin === komiConsumerUrl &&
      (router.pathname === "/" ||
        router.pathname === "/talent-register" ||
        router.pathname === "/invitation" ||
        router.pathname === "/trial"
        )
    );
  }, [router.pathname]);

  useEffect(() => {
    if (email) {
      form.setFieldsValue({ email: email });
    } else if (router.query?.email) {
      form.setFieldsValue({ email: router.query?.email });
    }
  }, [router, form, email]);

  useEffect(() => {
    if (user) {
      onOk();
    }
  }, [onOk, user]);

  useEffect(() => {
    if (signInError) {
      notification.error({
        message: signInError || "Your email and/or password do not match.",
      });
    }
  }, [signInError]);

  const sendSegmentEvent = useCallback(
    (data: any) => {
      AnalyticServices.track(SEGMENT_EVENT.LOGIN, {
        ...data,
        "Login method": "E-mail",
        Location: Cookies.get(KOMI_USER_LOCATION),
        Platform: isMobile ? "Responsive" : "Web",
        "Talent User ID": user?.id,
      });
    },
    [isMobile, user],
  );

  const isCollaboratorInvitation = useMemo(
    () => router?.asPath?.startsWith("/invitation"),
    [router],
  );

  const acceptInvitation = useCallback(() => {
    if (isCollaboratorInvitation) {
      dispatch(
        acceptCollaboratorInviteActions.REQUEST({
          form: {
            email: router?.query?.email,
            token: router?.query?.token,
          },
          refreshUser: true,
          onSuccess: () => router.push("/"),
          onAlreadyJoined: () => router.push("/"),
        }),
      );
    }
  }, [dispatch, isCollaboratorInvitation, router]);

  const showTwoFactorModal = (method: string, email: string, password: string) => {
    dispatch(
      toggleModalActions({
        modal: MODAL_TWO_FACTOR,
        status: true,
        data: {
          method,
          email,
          password,
        },
      }),
    );
  };

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    if (forgotPasswordStep === ForgotPasswordStep.Reset) {
      dispatch(
        resetPasswordActions.REQUEST({
          ...form.getFieldsValue(),
        }),
      );
    } else if (isTalentLogin) {
      const { email, password } = form.getFieldsValue();
      dispatch(
        checkUserExistedActions.REQUEST({
          email,
          password,
          onSuccess: (
            _: string,
            userId: number,
            phone2faEnabled: boolean,
            email2faEnabled: boolean,
            phoneOtp: string,
            emailVerified: boolean,
          ) => {
            if (typeof emailVerified === "boolean" && emailVerified === false) {
              return dispatch(
                toggleModalActions({
                  modal: MODAL_SIGNUP_ACTIVATE,
                  status: true,
                  data: {
                    userId,
                    context: MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_LOGIN,
                  },
                })
              );
            }

            if (phone2faEnabled && email2faEnabled) {
              showTwoFactorModal(SELECT_METHOD, email, password);
            } else if (phone2faEnabled) {
              const payload: RequestOtpRequest = otpRequest(PHONE, {
                phoneOtp,
                userId,
                email: undefined,
              });
              dispatch(requestOtpActions.REQUEST(payload));
              showTwoFactorModal(PHONE, email, password);
            } else {
              dispatch(
                talentLoginActions.REQUEST({
                  form: form.getFieldsValue(),
                  sendSegmentEvent: sendSegmentEvent,
                  onSuccess: isCollaboratorInvitation ? () => acceptInvitation() : () => router.push("/"),
                }),
              );
            }
          },
          onError: (error: any) =>
            notification.error({
              message: error,
            }),
        }),
      );
    } else {
      dispatch(
        signInActions.REQUEST({
          form: form.getFieldsValue(),
          sendSegmentEvent: sendSegmentEvent,
          onSuccess: () => acceptInvitation(),
        }),
      );
    }
  }, [acceptInvitation, dispatch, forgotPasswordStep, form, sendSegmentEvent, isTalentLogin]);

  useEffect(() => {
    if (!show) {
      setForgotPasswordStep(ForgotPasswordStep.None);
    }
  }, [show]);

  const handleForgotPasswordBack = React.useCallback(() => {
    setForgotPasswordStep(ForgotPasswordStep.None);
  }, []);

  const handleForgotPasswordClick = React.useCallback(() => {
    setForgotPasswordStep(ForgotPasswordStep.Email);
  }, []);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(getUserByIdActions.REQUEST());
    }
  }, [dispatch, resetPasswordSuccess]);

  const errorMessage = useCallback(
    (message: string) => {
      return (
        <Row align="top">
          <WarningIcon />
          <Text
            preset={isMobile ? "regular12" : "regular14"}
            className={classNames("d--block text--red m__l--4 flex--1", isMobile && "pt-3")}
          >
            {message}
          </Text>
        </Row>
      );
    },
    [isMobile],
  );

  if (forgotPasswordStep === ForgotPasswordStep.None) {
    return (
      <>
        {isLandingPage && (isLoginGoogle || !!router.query?.login_google) ? (
          <LoginGoogle isExistedUser login_hint={email} className="m__t--0" />
        ) : (
          <Form layout="vertical" form={form} requiredMark={false} onFinish={handleSubmit}>
            <Form.Item
              className="m__b--16"
              name="email"
              rules={[
                {
                  type: "email",
                  message: errorMessage("Invalid email address format"),
                },
                {
                  required: true,
                  message: errorMessage("Please enter your email"),
                },
              ]}
            >
              <Input className="input-rounded" disabled placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: errorMessage("Please enter your password") }]}
            >
              <Input.Password
                className="input-rounded"
                placeholder="Enter your password"
                visibilityToggle={false}
              />
            </Form.Item>

            <Row className={`m__y--${isMobile ? 16 : 24}`} align="middle" justify="center">
              <Col className="cursor-pointer" onClick={handleForgotPasswordClick}>
                <Text
                  className="text--blue opacity--08"
                  preset={isMobile ? "semibold14" : "semibold16"}
                >
                  Forgot password?
                </Text>
              </Col>
            </Row>

            <Form.Item className={isLandingPage ? "m__b--0" : ""}>
              <Button
                className={`btn-signin ${isLandingPage && "home-page"}`}
                size="large"
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                Sign in
              </Button>
            </Form.Item>

            {!isLandingPage && (
              <Form.Item className="m__b--0">
                <Button className="ant-btn-back" block onClick={showCreateAccountModal}>
                  <Text preset="regular16">go back</Text>
                </Button>
              </Form.Item>
            )}
          </Form>
        )}
      </>
    );
  }

  if (forgotPasswordStep === ForgotPasswordStep.Email) {
    return (
      <ForgotPasswordForm
        email={email}
        isHomePage={isLandingPage}
        onCancel={handleForgotPasswordBack}
      />
    );
  }

  return null;
};
