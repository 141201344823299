import { RootState } from "./../rootReducer";

export const selectTalentList = (rootState: RootState) => rootState.talentState?.talents;

export const selectTalentListTotalPages = (rootState: RootState) => rootState.talentState?.talentsTotalPages;

export const selectTalentTrendingList = (rootState: RootState) => rootState.talentState?.talentsTrending;

export const selectLoadingTalentList = (rootState: RootState) => rootState.talentState?.loading;

export const selectTalent = (rootState: RootState) => rootState.talentState?.talent;

export const selectTalentBasicInfo = (rootState: RootState) => rootState.talentState?.talentBasicInfo;

export const selectLoadingTalent = (rootState: RootState) => rootState.talentState?.loading;

export const selectLoadingTrendingTalent = (rootState: RootState) => rootState.talentState?.loadingTrending;

export const selectTalentError = (rootState: RootState) => rootState.talentState?.error;

export const selectTalentErrorCode = (rootState: RootState) => rootState.talentState?.errorCode;

export const selectTalentsByCategory = (rootState: RootState) => rootState.talentState?.talentsByCategory;
export const selectTalentsByCategoryLatestPage = (rootState: RootState) =>
  rootState.talentState?.talentsByCategoryLatestPage;

export const selectTalentsByCategoryTotalPages = (rootState: RootState) =>
  rootState.talentState?.talentsByCategoryTotalPages;

export const selectTrendingTalentsByCategory = (rootState: RootState) =>
  rootState.talentState?.trendingTalentsByCategory;
export const selectTrendingTalentsByCategoryLatestPage = (rootState: RootState) =>
  rootState.talentState?.trendingTalentsByCategoryLatestPage;

export const selectTalentAllExperiences = (rootState: RootState) => rootState.talentState.allExperiences;
export const selectTalentAllLatestPage = (rootState: RootState) => rootState.talentState.allLatestPage;
export const selectTalentIsAllLastPage = (rootState: RootState) => rootState.talentState.isAllLastPage;

export const selectTalentExclusiveContentExperiences = (rootState: RootState) =>
  rootState.talentState.exclusiveContentExperiences;
export const selectTalentExclusiveContentLatestPage = (rootState: RootState) =>
  rootState.talentState.exclusiveContentLatestPage;
export const selectTalentIsExclusiveContentLastPage = (rootState: RootState) =>
  rootState.talentState.isExclusiveContentLastPage;

export const selectTalentProducts = (rootState: RootState) => rootState.talentState?.products?.items;

export const selectTalentModules = (rootState: RootState) => rootState.talentState?.talentModules;

export const selectLoadingTalentModules = (rootState: RootState) => rootState.talentState?.loadingModules;

export const selectLoadingTalentProfile = (rootState: RootState) => rootState.talentState?.loadingTalentProfile;

export const selectIsPreSaveSuccess = (rootState: RootState) => !!rootState.talentState?.preSaveData;

export const selectActiveModule = (rootState: RootState) => rootState?.talentState?.activeModule;

export const selectPlayerPlaying = (rootState: RootState) => rootState?.talentState?.playerPlaying;
