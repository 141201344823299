import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { Text } from "components/Typography";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { ReactComponent as GoogleIcon } from "public/static/assets/icons/google-login.svg";
import React, { useCallback, useMemo } from "react";
import qs from "query-string";
import { ROLES } from "constants/auth";
import { komiConsumerUrl } from "services/DomainService";

interface IProps {
  className?: string;
  title?: string;
  login_hint?: string;
  isExistedUser?: boolean;
  //operation param introduced via ssx phase
  operation?: undefined | string;
  onClick?: () => void;
}

interface SignupProps {
  token?: string | string[] | undefined;
  email?: string | string[] | undefined;
  invitation?: string | string[] | undefined;
  newUser?: string | string[] | undefined;
  operation?: string;
}

/**
 * Component used to render Google Sign in operations
 * @returns
 */
const LoginGoogle: React.FC<IProps> = ({
  className,
  title,
  login_hint,
  isExistedUser,
  operation,
  onClick,
}) => {
  const router = useRouter();

  const isCollaboratorInvitation = useMemo(() => router?.asPath?.startsWith("/invitation"), [router]);

  const isTalentRegister = useMemo(() => router?.asPath?.startsWith("/talent-register"), [router]);

  const handleSignIn = useCallback(() => {
    let signupProps: SignupProps;

    //set the query params based on the operation
    if (operation === ROLES.SELFSIGN) {
      //set new user for ssx signup
      signupProps = {
        newUser: isExistedUser ? "false" : "",
        operation,
      };
    } else {
      //otherwise grab sign in queries from url params
      const { query } = router;
      signupProps = {
        token: query.token,
        email: query.email,
        invitation: query.invitation,
        newUser: isExistedUser ? "false" : query.newUser,
      };
    }

    if (title) {
      Cookies.set("KOMI_SIGNUP", "true");
    } else {
      Cookies.remove("KOMI_SIGNUP");
    }

    //params are passed to the server via url query params on callback
    const redirect_uri = `${komiConsumerUrl}/api/auth/callback/google`;

    const loginParams = new URLSearchParams({
      prompt: 'select_account',
      response_type: 'token',
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      redirect_uri,
      client_id: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID as string,
      flowName: 'GeneralOAuthFlow',
      ...login_hint && { login_hint },
      state: btoa(qs.stringifyUrl({
        url: window.location.href,
        query: {
          ...signupProps,
          login_google: isCollaboratorInvitation || isTalentRegister || operation ? true : undefined,
        },
      })),
    });

    console.log('loginParams: ', loginParams.toString());
    
    const googleLoginUrl = new URL(`https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?${loginParams.toString()}`)

    router.push(googleLoginUrl.toString());
  }, [router, title, isCollaboratorInvitation, isTalentRegister, isExistedUser, login_hint]);

  return (
    <Button
      className={`btn-login-social ${className}`}
      onClick={() => {
        onClick?.();
        handleSignIn();
      }}
      block
    >
      <Row justify="center" align="middle" gutter={12}>
        <Col>
          <GoogleIcon />
        </Col>
        <Col>
          <Text className="w-full text__align--center" preset="semibold16">
            {title ?? "Continue with Google"}
          </Text>
        </Col>
      </Row>
    </Button>
  );
};

export default LoginGoogle;
